import moment from 'moment';
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { toLower } from 'lodash';
import Resizer from 'react-image-file-resizer';
import Moment from 'react-moment';
import CONSTANTS from '../constants/constant';
import { FormattedNumber } from 'react-intl';

const ALPHABET = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
const mentionRegex = /\{\{(.*?)\}\}+/g;
const mentionDetailRegex = /(?<=\{\{)(.*?)(?=\}\})+/g;

export const VEHICLE_TYPES: string[] = [
  '1 TẤN',
  '2 TẤN',
  '3 TẤN',
  '4 TẤN',
  '5 TẤN',
  '6 TẤN',
  '7 TẤN',
  '8 TẤN',
  '2 CHÂN',
  '3 CHÂN',
  '4 CHÂN',
  '5 CHÂN',
  'ĐẦU KÉO',
  'MOOC SÀN',
  'MOOC XƯƠNG',
  'PHOOC LÙN',
  'CONT',
  'CONT CẮT NÓC',
];

export const VEHICLE_TYPE_1: string[] = [
  '1 TẤN',
  '2 TẤN',
  '3 TẤN',
  '4 TẤN',
  '5 TẤN',
  '6 TẤN',
  '7 TẤN',
  '8 TẤN',
  '2 CHÂN',
  '3 CHÂN',
  '4 CHÂN',
  '5 CHÂN',
];

export const VEHICLE_TYPE_2: string[] = ['ĐẦU KÉO', 'MOOC SÀN', 'MOOC XƯƠNG', 'PHOOC LÙN'];

export const VEHICLE_TYPE_3: string[] = ['CONT'];

export const VEHICLE_TYPE_4: string[] = ['CONT CẮT NÓC'];

export const VEHICLE_CONTAINER_TYPE_1: any = [
  { label: 'THÙNG KÍN', value: 'THÙNG KÍN' },
  { label: 'THÙNG BẠT', value: 'THÙNG BẠT' },
  { label: 'THÙNG LẠNH', value: 'THÙNG LẠNH' },
];

export const VEHICLE_CONTAINER_TYPE_2: any = [
  { label: 'NÓNG', value: 'NÓNG' },
  { label: 'LẠNH', value: 'LẠNH' },
];

export const UNIT_OPTIONS: any[] = [
  { label: 'XE', value: 'XE' },
  { label: 'TẤN', value: 'TẤN' },
  { label: 'KHỐI', value: 'KHỐI' },
  { label: 'KIỆN', value: 'KIỆN' },
  { label: 'THÙNG', value: 'THÙNG' },
  { label: 'BALET', value: 'BALET' },
  { label: 'LÔ', value: 'LÔ' },
  { label: 'CÁI', value: 'CÁI' },
  { label: 'KG', value: 'KG' },
  { label: 'TRÁI', value: 'TRÁI' },
];

export const PRICE_QUOTE_TYPES: string[] = ['CÓ XE NHẬN', 'THAM KHẢO GIÁ'];

export const PP_VAN_CHUYEN_OPTIONS: any[] = [
  {
    label: 'ĐƯỜNG BỘ',
    value: 'ĐƯỜNG BỘ',
  },
  {
    label: 'ĐƯỜNG SẮT',
    value: 'ĐƯỜNG SẮT',
  },
  {
    label: 'ĐƯỜNG BIỂN',
    value: 'ĐƯỜNG BIỂN',
  },
  {
    label: 'TRUCKING HÀNG 2 CHIỀU',
    value: 'TRUCKING HÀNG 2 CHIỀU',
  },
];

export const VERSION_WRAPPER: any = {
  52: '3.2.9',
  48: '3.2.6',
  39: '3.1.6',
  20: '3.0.9',
  19: '3.0.8',
  18: '3.0.8',
  17: '3.0.8',
  15: '3.0.7',
  13: '3.0.6',
  12: '3.0.5',
  11: '3.0.4',
  10: '3.0.3',
  7: '3.0.2',
  6: '3.0.1',
  5: '3.0.0',
};

export const PLATFORM_OPTIONS: any[] = [
  { label: 'Tất cả', value: '' },
  { label: 'ANDROID', value: 'android' },
  { label: 'IOS', value: 'ios' },
];

export const VIEW_TYPE_OPTIONS: any[] = [
  {
    label: 'Chỉ xem đơn của tôi',
    value: '1',
  },
  {
    label: 'Xem tất cả đơn',
    value: '',
  },
];

export const KIEU_HH_OPTIONS: any[] = [
  {
    label: 'Tất cả',
    value: '',
  },
  {
    label: 'Hàng nguyên xe',
    value: 'Hàng nguyên xe',
  },
  {
    label: 'Hàng ghép',
    value: 'Hàng ghép',
  },
];

export const CHAT_NAME_COLORS = [
  '#DB2D43',
  '#9F2B68',
  '#3B7A57',
  '#9966CC',
  '#0048BA',
  '#4B6F44',
  '#007FFF',
  '#DA1884',
  '#848482',
  '#2E5894',
  '#3D2B1F',
  '#000000',
  '#54626F',
  '#A57164',
  '#660000',
  '#0087BD',
  '#DE5D83',
  '#004225',
  '#CC5500',
  '#006B3C',
  '#00CC99',
  '#703642',
  '#2A52BE',
  '#E23D28',
  '#6495ED',
  '#006400',
  '#8B008B',
];

export const getValue = (value: any, defaultValue: any) => {
  return !isEmpty(value) ? value : defaultValue;
};

export const isJSON = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const toFormattedNumber = (value: number) => {
  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 3,
    minimumFractionDigits: 0,
  }).format(value);
};

export const indexTemplate = (data: any, col: any) => {
  return col.rowIndex + 1;
};

export const longDateTemplate = (rowData: any, col: any) => {
  return getDataInPath(rowData, col.field) ? (
    <Moment date={getDataInPath(rowData, col.field)} format={CONSTANTS.DATE_FORMAT.LONG} />
  ) : (
    <></>
  );
};

export const numberTemplate = (rowData: any, col: any) => {
  return rowData[col.field] ? <FormattedNumber value={rowData[col.field]} maximumFractionDigits={0} /> : <></>;
};

export const durationTemplate = (rowData: any, col: any) => {
  // if (rowData[col.field]) {
  //   const minute = Math.floor(rowData[col.field] / 60);
  //   return minute > 0 ? `${minute}p${rowData[col.field] % 60}s` : `${rowData[col.field] % 60}s`;
  // } else {
  //   return <></>;
  // }
  return rowData[col.field] ? formatDuration(rowData[col.field]) : <></>;
};

export const loadingText = () => {
  return <span className="loading-text"></span>;
};

export const longDateFormat = (date: any) => {
  return date ? <Moment date={date} format={CONSTANTS.DATE_FORMAT.LONG} /> : <></>;
};

export const getDifferenceBetweenTwoObject = (obj1: any, obj2: any) => {
  const result: any = {};
  if (Object.is(obj1, obj2)) {
    return {};
  }
  if (!obj2 || typeof obj2 !== 'object') {
    return obj2;
  }
  Object.keys(obj1 || {})
    .concat(Object.keys(obj2 || {}))
    .forEach((key) => {
      if (`${obj2[key]}` !== `${obj1[key]}` && !Object.is(obj1[key], obj2[key])) {
        result[key] = obj2[key];
      }
      if (typeof obj2[key] === 'object' && typeof obj1[key] === 'object') {
        const value = getDifferenceBetweenTwoObject(obj1[key], obj2[key]);
        if (value !== undefined && !isEmpty(value)) {
          result[key] = value;
        }
      }
    });
  return result;
};

export const getDataInPath = (data: any, field: string) => {
  const fields = field.split('.');
  if (fields.length === 1) {
    return data[fields[0]];
  } else if (fields.length === 2 && data[fields[0]] && data[fields[0]][fields[1]]) {
    return data[fields[0]][fields[1]];
  } else if (
    fields.length === 3 &&
    data[fields[0]] &&
    data[fields[0]][fields[1]] &&
    data[fields[0]][fields[1]][fields[2]]
  ) {
    return data[fields[0]][fields[1]][fields[2]];
  } else if (
    fields.length === 4 &&
    data[fields[0]] &&
    data[fields[0]][fields[1]] &&
    data[fields[0]][fields[1]][fields[2]] &&
    data[fields[0]][fields[1]][fields[2]][fields[3]]
  ) {
    return data[fields[0]][fields[1]][fields[2]][fields[3]];
  }
};

export const searchDataInPayload = (data: any[], displayFields: string[], search: string) => {
  if (!isEmpty(search)) {
    return data.filter((item: any) => {
      let isFound = false;
      for (const key of displayFields) {
        if (!isFound) {
          const fields = key.split('.');
          if (fields.length === 1 && item[key]) {
            isFound =
              removeVietnameseTones(`${toLower(item[key])}`).indexOf(removeVietnameseTones(toLower(search))) >= 0;
          } else if (fields.length === 2 && item[fields[0]] && item[fields[0]][fields[1]]) {
            isFound =
              removeVietnameseTones(`${toLower(item[fields[0]][fields[1]])}`).indexOf(
                removeVietnameseTones(toLower(search)),
              ) >= 0;
          } else if (
            fields.length === 3 &&
            item[fields[0]] &&
            item[fields[0]][fields[1]] &&
            item[fields[0]][fields[1]][fields[2]]
          ) {
            isFound =
              removeVietnameseTones(`${toLower(item[fields[0]][fields[1]][fields[2]])}`).indexOf(
                removeVietnameseTones(toLower(search)),
              ) >= 0;
          } else if (
            fields.length === 4 &&
            item[fields[0]] &&
            item[fields[0]][fields[1]] &&
            item[fields[0]][fields[1]][fields[2]] &&
            item[fields[0]][fields[1]][fields[2]][fields[3]]
          ) {
            isFound =
              removeVietnameseTones(`${toLower(item[fields[0]][fields[1]][fields[2]][fields[3]])}`).indexOf(
                removeVietnameseTones(toLower(search)),
              ) >= 0;
          }
        }
      }
      return isFound;
    });
  }
  return data;
};

export const searchDataInColumns = (data: any, filterCols: any) => {
  return data.filter((item: any) => {
    let isFound = true;
    for (const key of Object.keys(filterCols)) {
      const fields = key.split('.');
      if (fields.length === 1 && item[fields[0]]) {
        isFound =
          isFound &&
          removeVietnameseTones(`${toLower(item[fields[0]])}`).indexOf(
            removeVietnameseTones(toLower(filterCols[key].value)),
          ) >= 0;
      } else if (fields.length === 2 && item[fields[0]] && item[fields[0]][fields[1]]) {
        isFound =
          isFound &&
          removeVietnameseTones(`${toLower(item[fields[0]][fields[1]])}`).indexOf(
            removeVietnameseTones(toLower(filterCols[key].value)),
          ) >= 0;
      } else if (
        fields.length === 3 &&
        item[fields[0]] &&
        item[fields[0]][fields[1]] &&
        item[fields[0]][fields[1]][fields[2]]
      ) {
        isFound =
          isFound &&
          removeVietnameseTones(`${toLower(item[fields[0]][fields[1]][fields[2]])}`).indexOf(
            removeVietnameseTones(toLower(filterCols[key].value)),
          ) >= 0;
      } else if (
        fields.length === 4 &&
        item[fields[0]] &&
        item[fields[0]][fields[1]] &&
        item[fields[0]][fields[1]][fields[2]] &&
        item[fields[0]][fields[1]][fields[2]][fields[3]]
      ) {
        isFound =
          isFound &&
          removeVietnameseTones(`${toLower(item[fields[0]][fields[1]][fields[2]][fields[3]])}`).indexOf(
            removeVietnameseTones(toLower(filterCols[key].value)),
          ) >= 0;
      }
    }
    return isFound;
  });
};

export const sortDataInPayload = (data: any[], sortField: string, sortOrder: any) => {
  if (!isEmpty(sortField)) {
    return data.sort((o1: any, o2: any) => {
      const fieldArr = sortField.split('.');
      let value1 = 0;
      let value2 = 0;
      if (fieldArr.length === 1) {
        value1 = o1[fieldArr[0]];
        value2 = o2[fieldArr[0]];
      } else if (fieldArr.length === 2) {
        value1 = o1[fieldArr[0]] ? o1[fieldArr[0]][fieldArr[1]] : '';
        value2 = o2[fieldArr[0]] ? o2[fieldArr[0]][fieldArr[1]] : '';
      } else if (fieldArr.length === 3) {
        value1 = o1[fieldArr[0]] && o1[fieldArr[0]][fieldArr[1]] ? o1[fieldArr[0]][fieldArr[1]][fieldArr[2]] : '';
        value2 = o2[fieldArr[0]] && o2[fieldArr[0]][fieldArr[1]] ? o2[fieldArr[0]][fieldArr[1]][fieldArr[2]] : '';
      } else if (fieldArr.length === 4) {
        value1 =
          o1[fieldArr[0]] && o1[fieldArr[0]][fieldArr[1]] && o1[fieldArr[0]][fieldArr[1]][fieldArr[2]]
            ? o1[fieldArr[0]][fieldArr[1]][fieldArr[2]][fieldArr[3]]
            : '';
        value2 =
          o2[fieldArr[0]] && o2[fieldArr[0]][fieldArr[1]] && o2[fieldArr[0]][fieldArr[1]][fieldArr[2]]
            ? o2[fieldArr[0]][fieldArr[1]][fieldArr[2]][fieldArr[3]]
            : '';
      }
      if (value1 < value2) {
        return -1 * Number(sortOrder);
      }
      if (value1 > value2) {
        return 1 * Number(sortOrder);
      }
      return 0;
    });
  }
  return data;
};

export const removeVietnameseTones = (str: string) => {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
  str = str.replace(/Đ/g, 'D');
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, ' ');
  str = str.trim();
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  str = str.replace(
    /!|@|%|\^|\*|\(|\)|\+|\\=|\\<|\\>|\?|\/|,|\.|\\:|\\;|\\'|\\"|\\&|\\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
    ' ',
  );
  return str;
};

export const loadChunk = (filteredData: any[], index: number, length: number, totalRecords: number) => {
  if (index + length > totalRecords) {
    length = totalRecords - index;
  }
  const chunk = [];
  for (let i = 0; i < length; i++) {
    chunk[i] = { ...filteredData[i + index] };
  }
  return chunk;
};

export const isImage = (item: string) => {
  return item.match(/.(jpg|jpeg|png|gif)$/i);
};

export const cloneDeepArr = (item: any[]) => {
  const res = [];
  for (let i = 0; i < item.length; i++) {
    res.push({ ...item[i] });
  }
  return res;
};

export const resizeFile = (file: any) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      'JPEG',
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      'base64',
    );
  });

export const generateRandomText = (count: number) => {
  let rtn = '';
  for (let i = 0; i < count; i += 1) {
    rtn += ALPHABET.charAt(Math.floor(Math.random() * ALPHABET.length));
  }
  return rtn;
};

export const buildTagUser = (message: string) => {
  if (mentionRegex.test(message)) {
    const matchedRegex = message.match(mentionRegex);
    if (matchedRegex && !isEmpty(matchedRegex)) {
      for (let i = 0; i < matchedRegex.length; i++) {
        const matchedData = matchedRegex[i].match(mentionDetailRegex);
        if (matchedData && matchedData.length > 0) {
          const userIDs = matchedData[0].split('@');
          if (userIDs.length > 1) {
            message = message.replace(matchedRegex[i], `<a href="#">@${userIDs[1]}</a>`);
          }
        }
      }
    }
  }
  return message;
};

export const trimLastMessage = (message: string) => {
  return message.length > 40 ? message.substring(0, 40) + '...' : message;
};

export const getDuration = (date: any) => {
  const duration =
    moment.duration(moment().diff(moment(date))).asHours() < 24
      ? moment.duration(moment().diff(moment(date))).asMinutes() < 60
        ? `${moment
            .duration(moment().diff(moment(date)))
            .asMinutes()
            .toFixed(0)} phút trước`
        : `${moment
            .duration(moment().diff(moment(date)))
            .asHours()
            .toFixed(1)} giờ trước`
      : `${moment
          .duration(moment().diff(moment(date)))
          .asDays()
          .toFixed(0)} ngày trước`;
  return duration !== '' ? `(${duration})` : '';
};

export const formatChatTime = (date: any) => {
  if (moment(Number(date)).get('date') === moment().get('date')) {
    return `---------- ${moment(Number(date)).format('HH:mm')}, Hôm nay ----------`;
  }

  if (moment(Number(date)).get('date') === moment().subtract(1, 'day').get('date')) {
    return `---------- ${moment(Number(date)).format('HH:mm')}, Hôm qua ----------`;
  }

  return `---------- ${moment(Number(date)).format('HH:mm, DD/MM/YYYY')} ----------`;
};

export const formatSystemAdv = (item: any) => {
  try {
    const messageObj = item.message.split('\n');
    return (
      <div className="system-adv-content">
        <p className="text-bold">{messageObj[0]}</p>
        <p>{messageObj[1]}</p>
        <p>{messageObj[2]}</p>
        <p>{messageObj[3]}</p>
        <p>{messageObj[4]}</p>
        <p>{messageObj[5]}</p>
        <p>
          <small className="text-center d-block">{formatChatTime(item.created_at)}</small>
        </p>
      </div>
    );
  } catch (e) {
    return <div className="system-adv-content">{item.message}</div>;
  }
};

export const isReserving = (owner: any) => {
  return (
    !isEmpty(owner.reserved_at) && moment(owner.reserved_at).add(CONSTANTS.RESERVED_TIME, 'minutes').isAfter(moment())
  );
};

export const isAvailableForReserveInstant = (owner: any, userGroupID: string, userID: number) => {
  const priceQuotes =
    owner.price_quotes
      ?.filter((item: any) => {
        if (item.quote_type === 'CÓ XE NHẬN') {
          if (!isEmpty(owner.reserved_at)) {
            return moment(item.created_at).isBefore(moment(owner.reserved_at).add(CONSTANTS.RESERVED_TIME, 'minutes'));
          }
          return moment(item.created_at).isBefore(
            moment(owner.created_at).add(CONSTANTS.LOCKED_TIME_FOR_GROUP, 'minutes'),
          );
        }
        return false;
      })
      .sort((o1: any, o2: any) => {
        if (o1.driver_total === o2.driver_total) {
          return moment(o1.created_at).valueOf() - moment(o2.created_at).valueOf();
        }
        return o1.driver_total - o2.driver_total;
      })
      .reduce((acc: any[], item: any) => {
        let found = false;
        for (let i = 0; i < acc.length; i++) {
          if (item.created_by === acc[i].created_by) {
            found = true;
            break;
          }
        }
        if (!found) {
          acc.push(item);
        }
        return acc;
      }, []) || [];

  let lockedMinute = !isEmpty(owner.reserved_at)
    ? priceQuotes.length
    : CONSTANTS.LOCKED_TIME_FOR_GROUP + priceQuotes.length;
  for (let i = 0; i < priceQuotes.length; i++) {
    if (Number(priceQuotes[i].created_by) === userID) {
      lockedMinute = !isEmpty(owner.reserved_at) ? i : CONSTANTS.LOCKED_TIME_FOR_GROUP + i;
      break;
    }
  }

  return {
    available:
      userGroupID === owner.group_id ||
      moment(owner.reserved_at || owner.created_at)
        .add(lockedMinute, 'minutes')
        .isBefore(moment()),
    lockedMinute,
  };
};

export const formatChatMessageTime = (date: any) => {
  if (moment(Number(date)).get('date') === moment().get('date')) {
    return moment(Number(date)).format('HH:mm');
  }

  if (moment(Number(date)).get('date') === moment().subtract(1, 'day').get('date')) {
    return `Hôm qua, ${moment(Number(date)).format('HH:mm')}`;
  }

  return moment(Number(date)).format('DD/MM, HH:mm');
};

export const formatDuration = (second: number) => {
  return moment.utc(second * 1000).format('mm:ss');
};

export const formatTimeDuration = (beginTime: any, endTime: any) => {
  const second = moment(endTime).diff(moment(beginTime), 'seconds');
  if (second < 60) {
    return `${second}s`;
  } else if (second < 3600) {
    return `${Math.floor(second / 60)}p ${second % 60}s`;
  } else if (second < 86400) {
    const minute = Math.floor(second % 3600);
    return `${Math.floor(second / 3600)}g ${Math.floor(minute / 60)}p ${minute % 60}s`;
  } else {
    const hour = Math.floor(second % 86400);
    const minute = Math.floor(hour % 3600);
    return `${Math.floor(second / 86400)}ng ${Math.floor(hour / 3600)}g ${Math.floor(minute / 60)}p ${minute % 60}s`;
  }
};

export const formatDurationToTime = (time: any) => {
  const second = moment().diff(moment(time), 'seconds');
  if (second < 60) {
    return `${second}s`;
  } else if (second < 3600) {
    return `${Math.floor(second / 60)}p`;
  } else if (second < 86400) {
    return `${Math.floor(second / 3600)}g`;
  } else {
    return `${Math.floor(second / 86400)}ng`;
  }
};

export const formatDurationToTimeFull = (time: any) => {
  const second = moment().diff(moment(time), 'seconds');
  if (second < 60) {
    return `${second} giây`;
  } else if (second < 3600) {
    return `${Math.floor(second / 60)} phút`;
  } else if (second < 86400) {
    return `${Math.floor(second / 3600)} giờ`;
  } else {
    return `${Math.floor(second / 86400)} ngày`;
  }
};

export const autoConvertToHyperlink = (text: string) => {
  const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  return text.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');
};
