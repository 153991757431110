class Setting {
  env: string;
  apiServerUrl: string;
  chatServerUrl: string;
  socketServerUrl: string;

  constructor() {
    this.env = process.env.REACT_APP_ENV || EMPTY;
    this.apiServerUrl = process.env.REACT_APP_API_URL || EMPTY;
    this.chatServerUrl = process.env.REACT_APP_CHAT_URL || EMPTY;
    this.socketServerUrl = process.env.REACT_APP_SOCKET_URL || EMPTY;
  }
}

const EMPTY: string = '';

const config: Setting = new Setting();

export { Setting, config };
